// import 'babel-polyfill'
import Vue from "vue";
import EstrelApp from "./EstrelApp.vue";
import store from "./stores";
import router from "./router.js";
import Vuetify from "vuetify";
import config from "./config";
import "vuetify/dist/vuetify.min.css";
import socket from "./lib/websocket";
import './registerServiceWorker'

Vue.use(Vuetify);

socket.init(config.connection, store);
socket.connect();
Vue.prototype.$socket = socket;
store.$socket = socket;

Vue.config.productionTip = false;
Vue.config.devtools = config.devtools;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  template: "<EstrelApp/>",
  components: { EstrelApp },
});
