export default {
  name: 'connectionStore',
  namespaced: true,
  state: {
    pong: false
  },
  mutations: {
    setPong (state, value) {
      state.pong = value
    }
  },
  getters: {
    getPong: state => state.pong
  },
  actions: {
    pong ({ commit }) {
      console.log('Ponged')
      commit('setPong', true)
    }
  }
}
