<template>
  <div class="bucketSelector">
    <h3>Bitte wählen Sie den Anfangsbuchstaben ihres Nachnamens</h3>
    <bucket-item
      v-for="bucket in buckets"
      :key="bucket"
      :bucket="bucket"
    />
  </div>
</template>

<script>
import BucketItem from './BucketItem.vue'

export default {
  components: { BucketItem },
  name: 'BucketSelector',
  computed: {
    buckets () {
      return Object.keys(this.$store.getters['service/getBuckets'])
    }
  }
}
</script>

<style lang="scss">
  .bucketSelector {
    h3 {
      text-align: center;
      margin-bottom: 10px;
    }
  }
</style>
