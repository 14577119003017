function getFromLocalStorage (key) {
  let unparsed = localStorage.getItem(key)

  if (unparsed) {
    try {
      return JSON.parse(unparsed)
    } catch (e) {
      return unparsed
    }
  } else {
    return undefined
  }
}

class Storage {
  cache = {}

  constructor () {
    console.log('Construct Storage')
  }

  get (key) {
    let data = (this.cache[key] !== undefined)
      ? this.cache[key]
      : getFromLocalStorage(key)
    this.cache[key] = data

    return data
  }

  set (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
    this.cache[key] = value
  }

  remove (key) {
    localStorage.removeItem(key)
    delete this.cache[key]
  }
}

export default new Storage()
