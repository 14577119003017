import Storage from "./../lib/storage";
import router from "./../router";
import Vue from "vue";
import jwt_decode from "jwt-decode";

export default {
  name: "auth",
  namespaced: true,
  state: {
    user: undefined,
    permanent: false,
    role_id: undefined,
    user_id: undefined,
    displayname: undefined,
    hierarchy_home: undefined,
    limited: true,
    token: Storage.get("token"),
    isAuthenticated: false,
    isAuthenticating: false,
    loginError: undefined,
    passwordCheckError: undefined,
    locked: false,
    availableUsers: [],
    username: undefined,
  },
  mutations: {
    setAvailableUsers(state, users) {
      Vue.set(state, "availableUsers", users);
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticating = false;
      state.isAuthenticated = !!token;

      if (state.permanent) {
        Storage.set("token", token);
      }
    },
    setAuthenticating(state, value) {
      state.isAuthenticating = value;
    },
    setAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    setLoginError(state, error) {
      state.loginError = error;
    },
    setLocked(state, value) {
      state.locked = value;
      Storage.set("locked", state.locked);
    },
    setPayload(state, payload) {
      state.permanent = payload.permanent;
      state.role_id = payload.role_id;
      state.user_id = payload.user_id;
      state.hierarchy_home = payload.hierarchy_home;
      state.limited = payload.limited;
      state.displayname = payload.displayname;
      state.username = payload.username;

      if (state.limited) {
        router.push({
          path: "/service",
        });
      } else {
        router.push({
          path: "/",
        });
      }
    },
    error(state, error) {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.loginError = error;
    },
    setPasswordCheckError(state, error) {
      state.passwordCheckError = error;
    },
    logout(state, error) {
      state.user = undefined;
      state.token = undefined;
      Storage.remove("token");
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.loginError = error;
    },
  },
  getters: {
    getAvailableUsers: (state) => state.availableUsers,
    getCurrentUsername: (state) => state.username,
    isLoggedIn: (state) => state.isAuthenticated,
    getLoginError: (state) => state.loginError,
    isLimited: (state) => state.limited,
    isLoading: (state) => state.isLoading,
    isAuthenticated: (state) => state.isAuthenticated,
    isAuthenticating: (state) => state.isAuthenticating,
    getToken: (state) => state.token,
    getPasswordCheckError: (state) => state.passwordCheckError,
    isEstrel: (state) => state.hierarchy_home === 1101,
    // isEstrel: (state) => state.hierarchy_home === 14, // current hierarchy_home id for debugging
  },
  actions: {
    authenticated({ commit }, msg) {
      if (msg && msg.type === "error") {
        commit("logout", msg.data.msg);
      } else {
        let payload;
        let token = msg.data.token;
        try {
          // TODO: old jwt decoding, replaced with jwt_decode. Can be safely removed after a while
          // payload = JSON.parse(atob(token.split(".")[1]));
          payload = jwt_decode(token)
        } catch (e) {
          payload = {};
          token = undefined;
        }
        commit("setPayload", payload);
        commit("setToken", token);
        commit("service/setInitialized", false, { root: true });
        commit("viewState/setShowUnlimitDialog", false, { root: true });
      }
    },
    reconnect({ commit }, msg) {
      if (msg && msg.type === "error") {
        commit("logout", msg.data.error_code);
      } else {
        console.warn("auth/reconnect not implemented");
      }
    },
    login({ commit }) {
      commit("error", "Fehler beim Anmelden");
    },
    logout({ commit }, ownAction) {
      let msg = ownAction ? undefined : "Sie wurden vom Server abgemeldet";
      commit("logout", msg);
      commit("service/reset", undefined, {
        root: true,
      });
      commit("preproposal/reset", undefined, {
        root: true,
      });
    },
    limit() {
      console.warn("not implemented");
    },
    unlimit({ commit }) {
      commit("setPasswordCheckError", "Falsches Passwort");
    },
    sendAvailableUsers({ commit }, msg) {
      commit("setAvailableUsers", msg.data);
    },
  },
};
