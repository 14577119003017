import Vue from 'vue'
import Router from 'vue-router'

import ServiceListView from '@/components/service/ServiceListView.vue'
import ConformationListView from '@/components/confirmation/ConfirmationListView.vue'
import store from './stores'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      name: 'serviceList',
      path: '/service',
      component: ServiceListView
    },
    {
      name: 'confirmation',
      path: '/',
      component: ConformationListView,
      beforeEnter (to, from, next) {
        if (store.getters['auth/isLimited']) {
          next('/service')
        } else {
          next()
        }
      }
    },
    {
      path: '*',
      redirect: '/service'
    }
  ]
})

export default router
