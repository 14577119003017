<template>
  <div class="serviceElement">
    <div class="pic">
      <img v-bind:class="getImageClass"  v-bind:src="getEmployeeUrl"/>
    </div>
    <div class="smallContent" v-if="isMobile">
      <div class="actionRow">
        <div class="details">
          <label class="title" >{{ service.employee_lastname + ', ' + service.employee_firstname  }}</label>
          <label class="jobsiteLabel grey--text">{{ service.jobsite_label }}</label>
          <label class="jobsiteLabel grey--text">{{ service.company_label }}</label>
          <label class="jobsiteLabel grey--text">{{ serviceStartTime }}-{{ serviceEndTime }} {{ startDate }}</label>
        </div>
        <div class="action">
          <v-btn
            class="green white--text"
            v-on:click.stop="editStartTime"
            v-if="!hasStarted"
          ><v-icon x-large>play_arrow</v-icon>
          </v-btn>
          <v-btn
            class="red white--text"
            v-on:click.stop="editEndTime"
            v-if="hasStarted && !hasEnded"
          ><v-icon x-large>stop</v-icon>
          </v-btn>
          <h4 style="text-align: center" v-if="canAccept">{{ serviceState }}</h4>
        </div>
      </div>
      <div class="infoRow">
        <div class="start">
          <div>
            <label class="timeCaption grey--text text--lighten-1">START</label>
            <label class="subheading">{{ startTime }}</label>
          </div>
        </div>
        <div class="end">
          <div>
            <label class="timeCaption grey--text text--lighten-1">ENDE</label>
            <label class="subheading">{{ endTime }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="bigContent" v-else>
      <div class="details">
        <label class="title" >{{ service.employee_lastname + ', ' + service.employee_firstname  }}</label>
        <label class="jobsiteLabel grey--text">{{ service.jobsite_label }}</label>
        <label class="jobsiteLabel grey--text">{{ service.company_label }}</label>
      </div>
      <div class="booked">
        <label class="subheading grey--text text--lighten-1">{{ startDate }}</label>
        <label class="subheading grey--text text--lighten-1">{{ serviceStartTime }}-{{ serviceEndTime }}</label>
      </div>
      <div class="start">
        <label class="timeCaption grey--text text--lighten-1">START</label>
        <div>
          <label class="subheading">{{ startTime }}</label>
        </div>
      </div>
      <div class="end">
        <label class="timeCaption grey--text text--lighten-1">ENDE</label>
        <div>
          <label class="subheading">{{ endTime }}</label>
        </div>
      </div>
      <div class="action">
        <v-btn
          class="green white--text"
          v-on:click.stop="editStartTime"
          v-if="!hasStarted"
        ><v-icon x-large>play_arrow</v-icon>
        </v-btn>
        <v-btn
          class="red white--text"
          v-on:click.stop="editEndTime"
          v-if="hasStarted && !hasEnded"
        ><v-icon x-large>stop</v-icon>
        </v-btn>
        <h4 style="text-align: center" v-if="canAccept">{{ serviceState }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import time from '../../lib/time'

export default {
  name: 'serviceView',
  props: ['service'],
  computed: {
    serviceState () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      if (!preproposal) return 'Fehler'

      if (preproposal.timelessed && !preproposal.missed) {
        return 'Bestätigt'
      }

      if (preproposal.missed) {
        return 'Nicht stattgefunden'
      }

      return 'Bestätigt'
    },
    isMobile () {
      return this.$store.getters['viewState/isMobile']
    },
    hasStarted () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      return (preproposal && (preproposal.shift_start || preproposal.missed))
    },
    startDate () {
      return time.getDateStringWithoutYear(this.$props.service.booked_start)
    },
    serviceEndTime () {
      return time.getSimpleTimeString(this.$props.service.booked_end)
    },
    serviceStartTime () {
      return time.getSimpleTimeString(this.$props.service.booked_start)
    },
    startTime () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      return (preproposal && preproposal.shift_start) ? time.formatTime(preproposal.shift_start) : '-'
    },
    hasEnded () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      return (preproposal && (preproposal.shift_end || preproposal.missed))
    },
    endTime () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      return (preproposal && preproposal.shift_end) ? time.formatTime(preproposal.shift_end) : '-'
    },
    getEmployeeUrl () {
      let baseUrl = this.$store.getters['config/getImageUrl']
      let token = this.$store.getters['auth/getToken']

      if (this.$props.service.employee_pic_oid) {
        let employeeId = this.$props.service.employee_id

        return baseUrl + '?employee_id=' + employeeId + '&token=' + token
      } else {
        let companyId = this.$props.service.company_id

        return baseUrl + '/company?company_id=' + companyId + '&token=' + token
      }
    },
    getImageClass () {
      return this.$props.service.employee_pic_oid ? 'employee' : 'company'
    },
    canAccept () {
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)

      return (preproposal && ((preproposal.shift_start && preproposal.shift_end) || preproposal.missed))
    },
    isEstrel () {
      return this.$store.getters['auth/isEstrel']
    }
  },
  methods: {
    editStartTime () {
      if (this.$store.getters['config/getStartSelectable']) {
        this.$parent.showTimePickerDialog({
          service: this.$props.service,
          mode: 'start'
        })
      } else {
        let divider = this.$store.getters['config/getMinuteDivider']
        let startTime = time.isAfterNow(this.$props.service.booked_start)
          ? this.$props.service.booked_start
          : time.getTimestringFromSimpleTime(time.getNextAllowedTime(divider), this.$props.service.booked_start)
        let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)
        this.$socket.sendObj({
          namespace: 'preproposal',
          action: 'shift_start',
          data: {
            offer_id: this.$props.service.offer_id,
            entered: startTime,
            rereferenced_preproposal_id: preproposal ? preproposal.id : null
          }
        })
        this.$emit('timeSet', {
          msg: 'Ihre Schicht hat begonnen'
        })
      }
    },
    editEndTime () {
      if (!this.isEstrel) {
        this.$emit('showServiceTimeDialog', {
          service: this.$props.service,
          editMode: 'end'
        })
      } else {
        let endTime = time.lastQuarterHour()
        let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.$props.service.offer_id)
        this.$socket.sendObj({
          namespace: 'preproposal',
          action: 'shift_end',
          data: {
            offer_id: this.$props.service.offer_id,
            entered: endTime,
            rereferenced_preproposal_id: preproposal ? preproposal.id : null
          }
        })
        this.$emit('timeSet', {
          msg: 'Schönen Feierabend'
        })
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../css/color';

  .serviceElement {
    height: 120px;
    display: flex;
    background-color: white;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

    label {

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .pic {
      flex: 1 1 10%;
      min-width: 80px;
      max-width: 100px;
      height: 100%;
      border-right: 1px solid rgba(0,0,0,0.1);

      > img.employee {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > img.company {
        width: 80%;
        height: 80%;
        margin: 10%;
        object-fit: contain;
      }
    }

    .bigContent {
      flex: 1 1 80%;
      display: flex;
      max-width: calc(100% - 80px);

      .details {
        flex: 1 1 80%;
        display: inline-flex;
        flex-direction: column;
        align-self: center;
        overflow: hidden;
        /*max-width: calc(100% - 100px);*/

        label {
          padding-left: 10px;
        }
      }

      .start,
      .end,
      .action,
      .booked {
        border-left: 1px solid rgba(0,0,0,0.1);
        flex: 0 0 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .btn {
          height: 80px;
        }
      }
    }

    .smallContent {
      flex: 1 1 80%;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 80px);

      .actionRow {
        flex: 1 1 100%;
        display: flex;

        .details {
          flex: 1 1 80%;
          display: inline-flex;
          flex-direction: column;
          align-self: center;
          overflow: hidden;

          label {
            padding-left: 10px;
          }
        }

        .action {
          flex: 1 1 20%;
          min-width: 100px;
          max-width: 120px;
          display: inline-flex;
          flex-direction: column;
          align-self: center;

          > .btn {
            height: 50px;
          }
        }
      }

      .infoRow {
        flex: 0 0 40px;
        display: flex;

        > * {
          border-top: 1px solid rgba(0,0,0,0.1);
          flex: 1 1 33%;
          display: flex;
          align-items: center;

          > div {
            width: 100%;
            text-align: center;
          }

          &:not(:last-child) {
            border-right: 1px solid rgba(0,0,0,0.1);
          }

          label {
            padding-left: 10px;
          }
        }
      }
    }

  }

</style>
