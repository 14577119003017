import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/de'
moment.locale('de')

export default {
  formatTime (timestring) {
    return this.getSimpleTimeString(timestring)
  },

  getDateString (timestring) {
    return moment(timestring).format('DD.MM.YYYY')
  },

  getDateStringWithoutYear (timestring) {
    return moment(timestring).format('DD.MM.')
  },

  getSimpleTimeString (timestring) {
    return moment(timestring).format('HH[:]mm')
  },

  getTimestringFromSimpleTime (simpleTime, date) {
    return this.dateAndTime2Moment(date, simpleTime).toISOString()
  },

  lastQuarterHour () {
    const now = moment()
    const minutes = now.get('minutes')
    const roundedMinutes = Math.trunc(minutes / 15) * 15
    const val = now.set('minutes', roundedMinutes).toISOString()
    console.log(val)
    return val
  },

  isValid (timeString) {
    let parts = timeString.split(':')

    if (parts.length !== 2) {
      return false
    }
    let h = parseInt(parts[0])

    if (h < 0 || h >= 24) {
      return false
    }
    let m = parseInt(parts[1])

    return !(m < 0 || m > 59)
  },

  string2Object: function (timeString) {
    if (this.isValid(timeString)) {
      let split = timeString.split(':')

      return {
        hour: split[0],
        minute: split[1]
      }
    }

    return false
  },

  twoDigits: function (i) {
    return ('0' + i).slice(-2)
  },

  dateAndTime2Moment: function (date, time, timezone) {
    if (
      !date || !time ||
      !this.isValid(time)
    ) {
      return false
    }

    timezone = timezone || 'Europe/Berlin'

    let timeComponents = this.string2Object(time)
    let dayString = moment(date).tz(timezone).format('YYYY-MM-DD')

    let momentTimeString = dayString + ' ' +
      this.twoDigits(timeComponents.hour) +
      ':' +
      this.twoDigits(timeComponents.minute) +
      ':00'

    let momentTime = moment.tz(
      momentTimeString,
      timezone
    )

    return momentTime
  },

  isAllowedMinute (hour, minute, divider, range) {
    let now = new Date()
    let steps = Math.floor(range / divider)
    let nextAllowedMinute = Math.ceil(now.getMinutes() / divider) * divider + now.getHours() * 60

    if (nextAllowedMinute > 24 * 60) {
      nextAllowedMinute -= 24 * 60
    }
    let allowedMinutes = []
    for (let i = 0; i < steps; i++) {
      let newMinute = nextAllowedMinute + i * divider

      if (newMinute > 60 * 24) {
        newMinute = newMinute - 60 * 24
      }
      allowedMinutes.push(newMinute)
    }

    return allowedMinutes.indexOf(hour * 60 + minute) !== -1
  },

  isAllowedHour (hour, divider, range) {
    let now = new Date()
    let steps = Math.floor(range / divider)
    let nextAllowedMinute = Math.ceil(now.getMinutes() / divider) * divider + now.getHours() * 60

    if (nextAllowedMinute > 24 * 60) {
      nextAllowedMinute -= 24 * 60
    }
    let allowedMinutes = []
    for (let i = 0; i < steps; i++) {
      let newMinute = nextAllowedMinute + i * divider

      if (newMinute > 60 * 24) {
        newMinute = newMinute - 60 * 24
      }
      allowedMinutes.push(newMinute)
    }
    let allowedHour = false
    for (let i = 0; i < Math.floor(60 / divider); i++) {
      if (allowedMinutes.indexOf(hour * 60 + i * divider) !== -1) {
        allowedHour = true
      }
    }

    return allowedHour
  },

  /**
   * Returns the next (from now) allowed (by divider) time
   * @param  int divider
   * @return string timestring
   */
  getNextAllowedTime (divider) {
    let now = new Date()
    let nextAllowedMinute = Math.ceil(now.getMinutes() / divider) * divider + now.getHours() * 60

    if (nextAllowedMinute > 24 * 60) {
      nextAllowedMinute -= 24 * 60
    }
    let hour = Math.floor(nextAllowedMinute / 60)
    let minute = Math.floor(nextAllowedMinute % 60)

    return hour + ':' + minute
  },

  toMoment (string) {
    return moment(string)
  },

  isTimeBetween (time, startTime, endTime) {
    let startMoment = moment(startTime)
    let endMoment = moment(endTime)
    let testMoment = moment(time)

    return testMoment.isAfter(startMoment) &&
      testMoment.isBefore(endMoment)
  },

  isAllowedEndMinute () {
  },

  isAllowedEndHour () {
  },

  isNewYear () {
    return moment().dayOfYear() === 1
  },

  isAfterNow (time) {
    return moment(time).isAfter(moment())
  },

  setAfterTimeBorder (time, timeBorder) {
    let momentTime = moment(time)

    if (momentTime.isBefore(moment(timeBorder))) {
      momentTime.add(1, 'days')
    }

    return momentTime
  }
}
