import Vue from 'vue'

export default {
  name: 'preproposal',
  namespaced: true,
  state: {
    preproposals: {},
    preproposalList: []
  },
  mutations: {
    reset (state) {
      state.preproposals = {}
      state.preproposalList = []
    },
    update (state, preproposals) {
      if (!preproposals) {
        return
      }
      preproposals.forEach((preproposal) => {
        if (state.preproposalList.indexOf(preproposal.offer_id) === -1) {
          state.preproposalList.push(preproposal.offer_id)
        }
        Vue.set(state.preproposals, preproposal.offer_id, preproposal)
      })
    },
    remove (state, preproposalIds) {
      preproposalIds.forEach((preproposalId) => {
        state.preproposalList.splice(state.preproposalList.indexOf(preproposalId), 1)
        delete state.preproposals[preproposalId]
      })
    },
    addError (state, errorObj) {
      let preproposal = state.preproposals[errorObj.preproposal_id]
      preproposal.error = errorObj.error_msg
      Vue.set(state.preproposals, errorObj.preproposal_id, preproposal)
    }
  },
  getters: {
    getProposalForOfferId (state) {
      return (offerId) => state.preproposals[offerId]
    },
    getProposals (state) {
      return state.preproposalList.map((id) => state.preproposals[id])
    }
  },
  actions: {
    update ({ commit }, msg) {
      if (msg && msg.type === 'error') {
        console.warn('not implemented')
      } else {
        commit('update', msg.data.preproposals)
        msg.data.preproposals.forEach((preproposal) => {
          commit('service/resetProgress', preproposal.offer_id, { root: true })
        })
      }
    },
    remove ({ commit }, msg) {
      if (msg && msg.type === 'error') {
        console.warn('not implemented')
      } else {
        commit('remove', msg.data.preproposal_ids)
      }
    },
    confirm ({ commit }, msg) {
      if (msg && msg.type === 'error') {
        msg.data.forEach(function (error) {
          commit('addError', error)
        })
      }
    }
  }
}
