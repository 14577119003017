<template>
  <v-dialog
    persistent
    v-model="show"
    lazy
    width="290px"
  >
    <v-time-picker
      v-model="internalTime"
      scrollable
      format="24hr"
      :allowed-minutes="allowedMinutes"
    >
      <template>
        <v-card-actions>
          <v-btn flat @click.native="cancel">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="saveTime">Übernehmen</v-btn>
        </v-card-actions>
      </template>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import time from '../../lib/time'

export default {
  name: 'ConfirmationTimeDialog',
  props: ['time', 'service', 'editMode'],

  data: () => {
    return {
      internalTime: undefined
    }
  },

  watch: {
    time (newTime) {
      this.internalTime = newTime
    }
  },

  computed: {
    show: {
      get: function () { return this.$store.getters['viewState/getShowConfirmationTimeDialog'] },
      set: function (value) { this.$store.commit('viewState/setShowConfirmationTimeDialog', value) }
    }
  },

  methods: {
    allowedMinutes (value) {
      return value % this.$store.getters['config/getMinuteDivider'] === 0
    },
    cancel () {
      this.$store.commit('viewState/setShowConfirmationTimeDialog', false)
    },
    saveTime () {
      this.$store.commit('viewState/setShowConfirmationTimeDialog', false)
      let preproposal = this.$store.getters['preproposal/getProposalForOfferId'](this.service.offer_id)

      switch (this.editMode) {
        case 'start':
          this.$socket.sendObj({
            namespace: 'preproposal',
            action: 'shift_start',
            data: {
              offer_id: this.service.offer_id,
              entered: time.getTimestringFromSimpleTime(this.internalTime, this.service.booked_start),
              rereferenced_preproposal_id: preproposal ? preproposal.id : null
            }
          })

          break

        case 'end':
          this.$socket.sendObj({
            namespace: 'preproposal',
            action: 'shift_end_by_clock',
            data: {
              offer_id: this.service.offer_id,
              entered: this.internalTime,
              rereferenced_preproposal_id: preproposal ? preproposal.id : null
            }
          })

          break
        default:
          console.warn('Editmode value not valid: ', this.$data.editMode)
      }
    }
  }
}
</script>
