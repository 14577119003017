<template>
  <v-app id="app">
    <div v-if="isAuthenticating" class="spinnerContainer">
      <v-progress-circular
        indeterminate
        v-bind:size="70"
        v-bind:width="3"
      ></v-progress-circular>
      <h5>Anmelden...</h5>
    </div>
    <div v-else>
      <main-view v-if="isAuthenticated"></main-view>
      <LoginView v-else></LoginView>
    </div>
  </v-app>
</template>

<script>
import LoginView from "./components/LoginView.vue";
import MainView from "./components/main/MainView.vue";

export default {
  components: {
    MainView,
    LoginView,
  },
  name: "estrelApp",
  computed: {
    isAuthenticating() {
      return this.$store.getters["auth/isAuthenticating"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  created: function searchme() {
    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "SOCKET_ONOPEN" &&
        this.$store.getters["auth/getToken"]
      ) {
        console.log("Opened Socket");
        this.$store.commit("auth/setAuthenticating", true);
        this.$socket.sendObj({
          namespace: "auth",
          action: "reconnect",
          data: {
            token: this.$store.getters["auth/getToken"],
          },
        });
        setInterval(() => {
          this.$socket.sendObj({
            namespace: "service",
            action: "get",
          });
        }, 1000 * 60 * 60);
      }
    });
  },
};
</script>

<style>
.spinnerContainer {
  padding-top: 50px;
  text-align: center;
  width: 100%;
}
</style>
