<template>
  <v-container grid-list-md >
    <v-layout row wrap v-show="showFilter">
      <v-flex xs12 md6 lg3>
        <v-select
          label="Status"
          v-bind:items="this.$store.getters['service/getServiceStates']"
          v-model="filteredStates"
          item-text="label"
          item-value="id"
          multiple
          chips
        ></v-select>
      </v-flex>
      <v-flex xs12 md6 lg3>
        <v-select
          label="Dienstort"
          v-bind:items="this.$store.getters['service/getJobsites']"
          v-model="filteredWorkplaces"
          item-text="label"
          item-value="id"
          multiple
          chips
        ></v-select>
      </v-flex>
      <v-flex xs12 md6 lg3>
        <v-select
          label="Qualifikation"
          v-bind:items="this.$store.getters['service/getQualifications']"
          v-model="filteredQualifications"
          item-text="label"
          item-value="id"
          multiple
          chips
        ></v-select>
      </v-flex>
      <v-flex xs12 md6 lg3>
        <v-select
          label="Schicht"
          v-bind:items="this.$store.getters['service/getShifts']"
          v-model="filteredWorkshifts"
          multiple
          chips
        ></v-select>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ConfirmationFilterView',
  computed: {
    filteredStates: {
      get () { return this.$store.getters['service/getFilteredStates'] },
      set (value) { this.$store.commit('service/setFilteredStates', value) }
    },
    filteredWorkplaces: {
      get () { return this.$store.getters['service/getFilteredWorkplaces'] },
      set (value) { this.$store.commit('service/setFilteredWorkplaces', value) }
    },
    filteredQualifications: {
      get () { return this.$store.getters['service/getFilteredQualifications'] },
      set (value) { this.$store.commit('service/setFilteredQualifications', value) }
    },
    filteredWorkshifts: {
      get () { return this.$store.getters['service/getFilteredWorkshifts'] },
      set (value) { this.$store.commit('service/setFilteredWorkshifts', value) }
    },
    showFilter () {
      return this.$store.getters['service/getShowFilter']
    }
  },
  methods: {
    clearSearchField () {
      this.$store.commit('service/setSearchName', '')
    }
  }
}
</script>
