<template>
  <div>
    <v-toolbar dark
               v-bind:class="confirmRoute ? 'primary' : 'amber darken-2'"
               app
               clipped-left
               fixed
               height="90px"
    >
      <v-btn
        v-if="bucket"
        v-on:click="bucket = undefined"
        icon large>
        <v-icon xLarge>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>{{ bucket }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="confirmRoute" v-on:click.stop="showServiceView" color="primary">
        <v-icon>lock_outline</v-icon> Dienstzuweisung starten
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!confirmRoute" v-on:click.stop="showConfirmView" color="amber darken-2">
        <v-icon>lock_open</v-icon> Entsperren
      </v-btn>
      <v-btn large v-if="confirmRoute" icon v-on:click.stop="toggleShowFilter">
        <v-icon xLarge>filter_list</v-icon>
      </v-btn>
      <v-btn large icon v-if="confirmRoute" v-on:click.stop="logout">
        <v-icon xLarge>power_settings_new</v-icon>
      </v-btn>
    </v-toolbar>
    <unlimit-dialog></unlimit-dialog>
  </div>
</template>

<script>
import UnlimitDialog from '../service/UnlimitDialog.vue'

export default {
  name: 'MainHeaderView',
  components: {
    UnlimitDialog
  },
  computed: {
    confirmRoute () {
      return this.$route.name === 'confirmation'
    },
    isLimited () {
      return this.$store.getters['auth/isLimited']
    },
    bucket: {
      get () { return this.$store.getters['viewState/getBucket'] },
      set (value) { this.$store.commit('viewState/setBucket', value) }
    }
  },
  methods: {
    limitAccess () {
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'limit'
      })
    },
    removeLimit () {
      this.$store.commit('viewState/setShowUnlimitDialog', true)
    },
    logout () {
      console.log('Logout pressed')
      this.$store.dispatch('auth/logout', true)
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'logout'
      })
    },
    showConfirmView () {
      this.$store.commit('viewState/setShowUnlimitDialog', true)
    },
    showServiceView () {
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'limit'
      })
    },
    toggleShowFilter () {
      this.$store.commit('service/toggleShowFilter')
    }
  }
}
</script>

<style lang="scss">
  .toolbar {
    .icon.icon--x-large {
      font-size: 5rem;
    }
    .btn--icon.btn--large {
      width: 60px;
      height: 60px;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
</style>
