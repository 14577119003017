export default {
  name: 'config',
  namespaced: true,
  state: {
    imageUrl: '',
    minuteDivider: 15,
    allowedRange: 0,
    startSelectable: false
  },
  mutations: {
    setImageUrl (state, imageUrl) {
      state.imageUrl = imageUrl
    },
    setMinutesDivider (state, minuteDivider) {
      state.minuteDivider = minuteDivider
    },
    setAllowedRange (state, rangeInMinutes) {
      state.allowedRange = rangeInMinutes
    },
    setStartSelectable (state, value) {
      state.startSelectable = value
    }
  },
  getters: {
    getImageUrl (state) {
      return state.imageUrl
    },
    getMinuteDivider (state) {
      return state.minuteDivider
    },
    getAllowedRange (state) {
      return state.allowedRange
    },
    getStartSelectable (state) {
      return state.startSelectable
    }
  },
  actions: {
    set_global ({ commit }, msg) {
      if (msg && msg.type === 'error') {
        console.warn('set config error not implemented')
      } else {
        commit('setImageUrl', msg.data.image_url)
      }
    },
    set_user ({ commit }, msg) {
      if (msg && msg.type === 'error') {
        console.warn('setUser config error not implemented')
      } else {
        commit('setMinutesDivider', msg.data.worktime_minute_divider)
        commit('setAllowedRange', msg.data.allowed_range)
        commit('setStartSelectable', msg.data.start_selectable)
      }
    }
  }
}
