export default {
  name: 'viewState',
  namespaced: true,
  state: {
    drawer: false,
    showServiceTimeDialog: false,
    showConfirmationTimeDialog: false,
    showFeedbackDialog: false,
    showUnlimitDialog: false,
    screenSize: window.innerWidth,
    bucket: undefined
  },
  mutations: {
    setScreenSize (state, size) {
      state.screenSize = size
    },
    setDrawer (state, value) {
      state.drawer = value
    },
    setShowServiceTimeDialog (state, value) {
      state.showServiceTimeDialog = value
    },
    setShowUnlimitDialog (state, value) {
      state.showUnlimitDialog = value
    },
    setShowFeedbackDialog (state, value) {
      state.showFeedbackDialog = value
    },
    setBucket (state, value) {
      state.bucket = value
    },
    setShowConfirmationTimeDialog (state, value) {
      state.showConfirmationTimeDialog = value
    }
  },
  getters: {
    getDrawer (state) {
      return state.drawer
    },
    isMobile (state) {
      let ratio = window.devicePixelRatio
      ratio = ratio < 2 ? ratio : 1

      return state.screenSize * ratio < 1024
    },
    getShowUnlimitDialog: state => state.showUnlimitDialog,
    getShowServiceTimeDialog: state => state.showServiceTimeDialog,
    getShowFeedbackDialog: state => state.showFeedbackDialog,
    getShowConfirmationTimeDialog: state => state.showConfirmationTimeDialog,
    getBucket: state => state.bucket
  }
}
