import _ from "lodash";
import Vue from "vue";
import moment from "moment";

export default {
  name: "service",
  namespaced: true,
  state: {
    isInitialized: false,
    services: {},
    serviceList: [],
    filteredWorkplaces: [],
    filteredQualifications: [],
    filteredWorkshifts: [],
    filteredStates: [],
    searchName: "",
    showFilter: false,
    showDialog: false,
    serviceStates: [
      {
        id: 0,
        label: "alle ohne Startzeit",
      },
      {
        id: 1,
        label: "alle ohne Endzeit",
      },
    ],
  },
  mutations: {
    reset(state) {
      state.isInitialized = false;
      state.services = {};
      state.serviceList = [];
      state.filteredQualifications = [];
      state.filteredWorkplaces = [];
      state.filteredWorkshifts = [];
      state.filteredStates = [0];
      state.searchName = "";
      state.showFilter = false;
      state.showDialog = false;
    },
    update(state, services) {
      if (!services) {
        return;
      }
      state.isInitialized = true;
      services.forEach((service) => {
        if (state.serviceList.indexOf(service.offer_id) === -1) {
          state.serviceList.push(service.offer_id);
        }
        service.progressAccepting = false;
        service.progressMissing = false;
        service.progressStart = false;
        service.progressEnd = false;
        Vue.set(state.services, service.offer_id, service);
      });
    },
    resetProgress(state, serviceId) {
      let service = state.services[serviceId];

      if (service) {
        service.progressAccepting = false;
        service.progressMissing = false;
        service.progressStart = false;
        service.progressEnd = false;
        Vue.set(state.services, service.offer_id, service);
      }
    },
    set(state, services) {
      if (!services) {
        return;
      }
      state.services = {};
      state.serviceList = [];
      state.isInitialized = true;
      services.forEach((service) => {
        if (state.serviceList.indexOf(service.offer_id) === -1) {
          state.serviceList.push(service.offer_id);
        }
        service.progressAccepting = false;
        service.progressMissing = false;
        service.progressStart = false;
        service.progressEnd = false;
        Vue.set(state.services, service.offer_id, service);
      });
    },
    remove(state, offerIds) {
      offerIds.forEach((offerId) => {
        state.serviceList.splice(state.serviceList.indexOf(offerId), 1);
        delete state.services[offerId];
      });
    },
    setFilteredWorkplaces(state, data) {
      state.filteredWorkplaces = data;
    },
    setFilteredQualifications(state, data) {
      state.filteredQualifications = data;
    },
    setFilteredWorkshifts(state, data) {
      state.filteredWorkshifts = data;
    },
    setFilteredStates(state, data) {
      state.filteredStates = data;
    },
    setSearchName(state, value) {
      state.searchName = value;
    },
    toggleShowFilter(state) {
      state.showFilter = !state.showFilter;
    },
    setShowDialog(state, value) {
      state.showDialog = value;
    },
    setInitialized(state, value) {
      state.isInitialized = value;
    },
  },
  getters: {
    isInitialized: (state) => state.isInitialized,
    getFilteredServices(state, getters, rootState, rootGetters) {
      return state.serviceList
        .filter((serviceId) => {
          let getServiceState = function(service, preproposal) {
            if (preproposal && preproposal.missed) {
              return 4;
            }

            if (preproposal && preproposal.timelessed && !preproposal.missed) {
              return 3;
            }

            if (preproposal && preproposal.shift_end) {
              return 2;
            }

            if (preproposal && preproposal.shift_start) {
              return 1;
            }

            return 0;
          };
          let service = state.services[serviceId];
          let preproposal = rootGetters["preproposal/getProposalForOfferId"](
            service.offer_id
          );

          // filter by time
          const serviceStart = moment(service.booked_start).subtract(1, "hour");
          const serviceEnd = moment(service.booked_end).add(6, "hour");
          const now = moment();

          if (!(serviceStart.isBefore(now) && serviceEnd.isAfter(now))) {
            // console.log('Raus: ', serviceStart.format('DD - HH:mm'), serviceEnd.format('DD - HH:mm'), service)

            return false;
          }

          let serviceState = getServiceState(service, preproposal);

          if (
            state.filteredStates.length > 0 &&
            state.filteredStates.indexOf(serviceState) === -1
          ) {
            return false;
          }

          if (
            state.filteredWorkplaces.length > 0 &&
            state.filteredWorkplaces.indexOf(service.jobsite_id) === -1
          ) {
            return false;
          }

          if (
            state.filteredQualifications.length > 0 &&
            state.filteredQualifications.indexOf(service.qualification_id) ===
              -1
          ) {
            return false;
          }

          if (
            state.filteredWorkshifts.length > 0 &&
            state.filteredWorkshifts.indexOf(service.shift_type) === -1
          ) {
            return false;
          }
          let employeeName =
            service.employee_lastname + service.employee_firstname;

          return !(
            state.searchName &&
            employeeName
              .toLowerCase()
              .indexOf(state.searchName.toLowerCase()) === -1
          );
        })
        .map((id) => state.services[id])
        .sort((a, b) => {
          let nameA = a.employee_lastname + a.employee_firstname;
          let nameB = b.employee_lastname + b.employee_firstname;

          return nameA.localeCompare(nameB);
        });
    },
    getBuckets(state, getters) {
      let filteredServices = getters["getFilteredServices"];
      let buckets = _.groupBy(filteredServices, (service) =>
        service.employee_lastname.substr(0, 1)
      );

      return buckets;
    },
    getSelectedBucket: (state, getters) => (bucket) => {
      let buckets = getters["getBuckets"];

      return bucket ? buckets[bucket] : [];
    },
    getServiceStates(state) {
      return state.serviceStates;
    },
    getServices: (state) => state.serviceList.map((id) => state.services[id]),
    getQualifications: (state) =>
      _.uniqBy(
        state.serviceList.map((serviceId) => {
          return {
            id: state.services[serviceId].qualification_id,
            label: state.services[serviceId].qualification_label,
          };
        }),
        "id"
      ),
    getJobsites: (state) =>
      _.uniqBy(
        state.serviceList.map((serviceId) => {
          return {
            id: state.services[serviceId].jobsite_id,
            label: state.services[serviceId].jobsite_label,
          };
        }),
        "id"
      ),
    getShifts: (state) =>
      _.uniq(
        state.serviceList.map(
          (serviceId) => state.services[serviceId].shift_type
        )
      ),
    getFilteredWorkplaces: (state) => state.filteredWorkplaces,
    getFilteredQualifications: (state) => state.filteredQualifications,
    getFilteredWorkshifts: (state) => state.filteredWorkshifts,
    getFilteredStates: (state) => state.filteredStates,
    getShowFilter: (state) => state.showFilter,
    getShowDialog: (state) => state.showDialog,
    getSearchName: (state) => state.searchName,
  },
  actions: {
    update({ commit }, msg) {
      commit("update", msg.data.services);
    },
    set({ commit }, msg) {
      commit("set", msg.data.services);
    },
    get() {
      console.warn("Fehler beim Holen der Dienste");
    },
    remove({ commit }, msg) {
      if (msg && msg.type === "error") {
        console.warn("Not implemented");
      } else {
        commit("remove", msg.data.offer_ids);
      }
    },
  },
};
