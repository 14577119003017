<template>
  <div class="confirmationElement">
    <div class="pic">
      <img v-bind:class="getImageClass" v-bind:src="getEmployeeUrl" />
    </div>

    <div class="smallContent" v-if="isMobile">
      <div class="actionRow">
        <div class="details">
          <label class="title">{{
            service.employee_lastname + ", " + service.employee_firstname
          }}</label>
          <label class="jobsiteLabel grey--text">{{
            service.jobsite_label
          }}</label>
          <label class="jobsiteLabel grey--text">{{
            service.company_label
          }}</label>
          <label class="jobsiteLabel grey--text"
            >{{ serviceStartTime }}-{{ serviceEndTime }} {{ startDate }}</label
          >
        </div>
        <div class="action" v-if="isFinished">
          <h3 style="text-align: center">{{ serviceState }}</h3>
        </div>
        <div class="action" v-else>
          <v-btn
            class="red white--text"
            v-on:click.stop="dismiss"
            v-bind:disabled="isFinished"
            :loading="service.progressMissing"
          >
            <v-icon x-large>clear</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="infoRow">
        <div class="start" v-on:click.stop="editStartTime">
          <label class="timeCaption grey--text text--lighten-1">START</label>
          <v-progress-circular
            v-if="service.progressStart"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <label v-else class="subheading">{{ startTime }}</label>
        </div>
        <div class="end" v-on:click.stop="editEndTime">
          <label class="timeCaption grey--text text--lighten-1">ENDE</label>
          <v-progress-circular
            v-if="service.progressEnd"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <label class="subheading" v-else>{{ endTime }}</label>
        </div>
      </div>
    </div>

    <div class="bigContent" v-else>
      <div class="details">
        <label class="title">{{
          service.employee_lastname + ", " + service.employee_firstname
        }}</label>
        <label class="jobsiteLabel grey--text">{{
          service.jobsite_label
        }}</label>
        <label class="jobsiteLabel grey--text">{{
          service.company_label
        }}</label>
      </div>
      <div class="booked">
        <label class="subheading grey--text text--lighten-1">{{
          startDate
        }}</label>
        <label class="subheading grey--text text--lighten-1"
          >{{ serviceStartTime }}-{{ serviceEndTime }}</label
        >
      </div>
      <div class="start" v-on:click.stop="editStartTime">
        <label class="timeCaption grey--text text--lighten-1">START</label>
        <v-progress-circular
          v-if="service.progressStart"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <label v-else class="subheading">{{ startTime }}</label>
      </div>
      <div class="end" v-on:click.stop="editEndTime">
        <label class="timeCaption grey--text text--lighten-1">ENDE</label>
        <v-progress-circular
          v-if="service.progressEnd"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <label v-else class="subheading">{{ endTime }}</label>
      </div>
      <div class="action" v-if="isFinished">
        <h3>{{ serviceState }}</h3>
      </div>
      <div class="action" v-else>
        <v-btn
          class="red white--text"
          v-on:click.stop="dismiss"
          v-bind:disabled="isFinished"
          :loading="service.progressMissing"
        >
          <v-icon x-large>clear</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import time from "../../lib/time";

export default {
  name: "ConfirmationItemView",
  props: ["service"],
  computed: {
    serviceState() {
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.$props.service.offer_id);

      if (!preproposal) return "Fehler";

      if (preproposal.timelessed && !preproposal.missed) {
        return "Bestätigt";
      }

      if (preproposal.missed) {
        return "Nicht stattgefunden";
      }

      return "Fehler";
    },
    isMobile() {
      return this.$store.getters["viewState/isMobile"];
    },
    serviceEndTime() {
      return time.getSimpleTimeString(this.$props.service.booked_end);
    },
    serviceStartTime() {
      return time.getSimpleTimeString(this.$props.service.booked_start);
    },
    startTime() {
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.$props.service.offer_id);

      return preproposal && preproposal.shift_start
        ? time.formatTime(preproposal.shift_start)
        : "";
    },
    startDate() {
      return time.getDateString(this.$props.service.booked_start);
    },
    endTime() {
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.$props.service.offer_id);

      return preproposal && preproposal.shift_end
        ? time.formatTime(preproposal.shift_end)
        : "";
    },
    isFinished() {
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.$props.service.offer_id);

      return preproposal && (preproposal.timelessed || preproposal.missed);
    },
    canAccept() {
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.$props.service.offer_id);

      return preproposal && preproposal.shift_start && preproposal.shift_end;
    },
    getEmployeeUrl() {
      let baseUrl = this.$store.getters["config/getImageUrl"];
      let token = this.$store.getters["auth/getToken"];

      if (this.$props.service.employee_pic_oid) {
        let employeeId = this.$props.service.employee_id;

        return baseUrl + "?employee_id=" + employeeId + "&token=" + token;
      } else {
        let companyId = this.$props.service.company_id;

        return baseUrl + "/company?company_id=" + companyId + "&token=" + token;
      }
    },
    getImageClass() {
      return this.$props.service.employee_pic_oid ? "employee" : "company";
    },
  },
  methods: {
    editStartTime() {
      this.$parent.showTimePickerDialog({
        service: this.$props.service,
        mode: "start",
      });
    },
    editEndTime() {
      this.$parent.showTimePickerDialog({
        service: this.$props.service,
        mode: "end",
      });
    },
    confirm() {
      this.service.progressAccepting = true;
      this.$socket.sendObj({
        namespace: "preproposal",
        action: "confirm",
        data: {
          preproposal_ids: [
            this.$store.getters["preproposal/getProposalForOfferId"](
              this.$props.service.offer_id
            ).id,
          ],
        },
      });
    },
    dismiss() {
      this.service.progressMissing = true;
      this.$socket.sendObj({
        namespace: "service",
        action: "missed",
        data: {
          service_id: this.$props.service.offer_id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../css/color";

.confirmationElement {
  height: 120px;
  display: flex;
  background-color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .pic {
    flex: 1 1 10%;
    min-width: 80px;
    max-width: 100px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    > img.employee {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > img.company {
      width: 80%;
      height: 80%;
      margin: 10%;
      object-fit: contain;
    }
  }

  .bigContent {
    flex: 1 1 80%;
    display: flex;
    max-width: calc(100% - 80px);

    .details {
      flex: 1 1 80%;
      display: inline-flex;
      flex-direction: column;
      align-self: center;
      overflow: hidden;

      label {
        padding-left: 10px;
      }
    }

    .start,
    .end,
    .booked {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      flex: 0 0 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .action {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      flex: 0 0 110px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > .btn {
        height: 80px;
      }
    }
  }

  .smallContent {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 80px);

    .actionRow {
      flex: 1 1 100%;
      display: flex;

      .details {
        flex: 1 1 80%;
        display: inline-flex;
        flex-direction: column;
        align-self: center;
        overflow: hidden;

        label {
          padding-left: 10px;
        }
      }

      .action {
        flex: 1 1 10%;
        min-width: 110px;
        align-self: center;
        display: block;
        > .btn {
          height: 50px;
        }
      }
    }

    .infoRow {
      flex: 0 0 40px;
      display: flex;

      > * {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        flex: 1 1 33%;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        label {
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
