<template>
  <v-dialog
    v-model="show"
    width="290px"
  >
    <div class="white" v-on:click="closeDialog">
      <h3>{{ message }}</h3>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'FeedbackDialog',
  props: ['message'],
  computed: {
    show: {
      get: function () { return this.$store.getters['viewState/getShowFeedbackDialog'] },
      set: function (value) { this.$store.commit('viewState/setShowFeedbackDialog', value) }
    }
  },
  watch: {
    show (value) {
      if (value) {
        const store = this.$store
        setTimeout(() => {
          store.commit('viewState/setShowFeedbackDialog', false)
        }, 2000)
      }
    }
  },
  methods: {
    closeDialog () {
      this.$store.commit('viewState/setShowFeedbackDialog', false)
    }
  }
}
</script>

<style lang="scss">
  .white {
    padding: 30px;
    text-align: center;
    background-color: white;
  }
</style>
