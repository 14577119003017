export default {
  name: "socket",
  namespaced: false,
  state: {
    wsConnected: false,
    online: navigator.onLine,
    socketError: undefined,
  },
  mutations: {
    online(state) {
      state.online = navigator.onLine;

      if (state.online) {
        this.$socket.connect();
      } else {
        this.$socket.close();
      }
    },
    SOCKET_ONOPEN(state) {
      console.log("Socket connected...");
      state.wsConnected = true;
    },
    SOCKET_ONERROR(state, error) {
      console.warn("Socket error occured: ", error);
      state.socketError = error;
      state.wsConnected = false;
    },
    SOCKET_ONCLOSE(state, error) {
      console.error("Socket closed", error);
      state.wsConnected = false;
    },
    SOCKET_ONMESSAGE(state, msg) {
      console.warn("Unhandled message: ", msg);
    },
    SOCKET_RECONNECT(state, msg) {
      console.info("Reconnecting: ", msg);
    },
    SOCKET_RECONNECT_ERROR(state, msg) {
      console.info("SOCKET_RECONNECT_ERROR: ", msg);
    },
  },
  getters: {
    isOnline: (state) => state.online,
    isConnected: (state) => state.wsConnected,
  },
};
