<template>
  <v-flex xs12>
    <confirmation-filter-view/>
    <v-container fluid style="min-height: 0;" grid-list-lg>
      <v-layout v-if="!bucket" column>
        <bucket-selector v-model="bucket"/>
      </v-layout>
      <v-layout v-show="bucket" column>
        <v-flex xs12 v-for="service in getFilteredServices" v-bind:key="service.offer_id">
          <confirmation-item-view
            v-bind:service="service"
          ></confirmation-item-view>
        </v-flex>
      </v-layout>
    </v-container>
    <confirmation-time-dialog
      :time="time"
      :editMode="editMode"
      :service="service"
    />
  </v-flex>
</template>

<script>
import ConfirmationFilterView from './ConfirmationFilterView.vue'
import ConfirmationItemView from './ConfirmationItemView.vue'
import time from '../../lib/time'
import BucketSelector from '../main/BucketSelector.vue'
import ConfirmationTimeDialog from './ConfirmationTimeDialog.vue'

export default {
  components: {
    ConfirmationTimeDialog,
    BucketSelector,
    ConfirmationItemView,
    ConfirmationFilterView
  },
  name: 'ConfirmationListView',
  data () {
    return {
      time: undefined,
      editMode: undefined,
      service: undefined,
      message: 'Got it Dude'
    }
  },
  created () {
    if (!this.$store.getters['service/isInitialized']) {
      this.$socket.sendObj({
        namespace: 'service',
        action: 'get'
      })
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'getAvailableUsers'
      })
    }
  },
  computed: {
    getFilteredServices () {
      return this.$store.getters['service/getSelectedBucket'](this.bucket)
    },
    bucket: {
      get () { return this.$store.getters['viewState/getBucket'] },
      set (value) { this.$store.commit('viewState/setBucket', value) }
    }
  },
  methods: {
    showTimePickerDialog (data) {
      this.editMode = data.mode
      this.service = data.service

      this.$data.time = (data.mode === 'end')
        ? time.getSimpleTimeString(data.service.booked_end)
        : time.getSimpleTimeString(data.service.booked_start)
      this.$store.commit('viewState/setShowConfirmationTimeDialog', true)
    }
  }
}
</script>
