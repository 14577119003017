<template>
  <div>
    <main-header-view></main-header-view>
    <v-content>
      <v-alert v-if="isOffline" color="error" icon="warning" value="true">
        Ihre Verbindung zum Internet ist unterbrochen
      </v-alert>
      <v-alert v-if="noWebsocket" color="error" icon="autorenew" value="true" v-on:click="reConnect">
        <h4>Ihre Verbindung zum Server ist unterbrochen. Klicken Sie hier um einen neuen Verbindungsversuch zu machen</h4>
      </v-alert>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import MainHeaderView from './MainHeaderView.vue'

export default {
  components: {
    MainHeaderView
  },
  name: 'MainView',
  computed: {
    isOffline () {
      return !this.$store.getters.isOnline
    },
    noWebsocket () {
      return !this.$store.getters.isConnected
    }
  },
  methods: {
    reConnect () {
      this.$socket.connect()
    }
  }
}
</script>
