<template>
  <div>
    <v-toolbar dark class="primary">
      <v-toolbar-title>Timeless</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout>
        <v-flex xs12 sm6 offset-sm3>
          <v-card>
            <v-toolbar dark class="primary">
              <v-toolbar-title>Anmelden</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-alert v-if="asyncLoginError" class="red lighten-1" icon="warning" value="true" dismissible v-on:click.stop="deleteError">
                {{ asyncLoginError }}
              </v-alert>
              <v-form
                v-model="valid"
                ref="form"
                lazy-validation
                @keyup.enter="login"
              >
                <v-text-field
                  name="name"
                  label="Benutzer"
                  tabindex="1"
                  autofocus
                  v-model="name"
                  :rules="nameRules"
                  @keyup.enter="login"
                ></v-text-field>
                <v-text-field
                  name="password"
                  label="Password"
                  tabindex="2"
                  v-model="password"
                  :rules="passwordRules"
                  :type="'password'"
                  @keyup.enter="login"
                ></v-text-field>
                <v-checkbox
                  label="Angemeldet bleiben"
                  v-model="permanent"
                  @keyup.enter="login"
                  tabindex="3"
                  light
                ></v-checkbox>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-btn
                v-on:click.stop="login"
                v-if="isConnected"
                flat
                class="orange--text"
                tabindex="4"
              >Anmelden</v-btn>
            </v-card-actions>
            <v-layout row>
              <label class="subheading ml-3 mb-3" v-if="!isConnected">Verbindung zu InSitu wird hergestellt...</label>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data () {
    return {
      valid: true,
      name: '',
      password: '',
      permanent: false,
      nameRules: [
        (v) => !!v || 'Bitte geben Sie ihren Benutzernamen ein'
      ],
      passwordRules: [
        (v) => !!v || 'Bitte geben Sie ihr Passwort ein'
      ]
    }
  },
  computed: {
    asyncLoginError () {
      return this.$store.getters['auth/getLoginError']
    },
    isConnected () {
      return this.$store.getters['isConnected']
    }
  },
  methods: {
    deleteError () {
      console.log('Delete Error')
      this.$store.commit('auth/error', undefined)
    },
    login () {
      // wegen lazyEvaluation der Form muss name mit rein
      if (this.$data.valid && this.$data.name) {
        this.$socket.sendObj({
          namespace: 'auth',
          action: 'login',
          data: {
            username: this.$data.name,
            password: this.$data.password,
            permanent: this.$data.permanent
          }
        })
      }
    }
  }
}
</script>
