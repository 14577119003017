import config from "../config";
import uuid4 from "uuid4";

function makeSocket() {
  let url;
  let store;
  let webSocket;
  let reconnectInterval;
  let pingTimeout;
  // eslint-disable-next-line no-unused-vars
  let pendingRequests = {};
  let pendingRequestInterval;
  let checkPongTimeout;
  let actions = {
    init(_url, _store) {
      url = _url;
      store = _store;

      if (pendingRequestInterval) {
        clearInterval(pendingRequestInterval);
      }
      pendingRequestInterval = setInterval(actions.sendPendingRequests, 5000);
    },
    connect() {
      try {
        if (webSocket) {
          webSocket.close();
        }
        setTimeout(() => {
          webSocket = new WebSocket(url);
          actions.onEvent();
        }, 50);
      } catch (e) {
        webSocket = undefined;
        console.warn("Error connecting WebSocket: ", e);
      }
    },
    sendObj(msg) {
      const uuid = uuid4();
      msg.transaction_id = uuid;
      pendingRequests[uuid] = msg;
      webSocket.send(JSON.stringify(msg));
    },
    sendPendingRequests() {
      let props = Object.keys(pendingRequests);
      for (let i = 0; i < props.length; i++) {
        if (Object.prototype.hasOwnProperty.call(pendingRequests, props[i])) {
          webSocket.send(JSON.stringify(pendingRequests[props[i]]));
        }
      }
    },
    close() {
      webSocket.close(1000, "Closed by user");
    },
    checkPong() {
      let ponged = store.getters["connection/getPong"];

      if (!ponged) {
        console.warn("Reconnect triggered!!!");
        actions.connect();
        store.commit("SOCKET_ONCLOSE", "No Pong recieved");
      }
    },
    ping() {
      store.commit("connection/setPong", false);
      actions.sendObj({
        namespace: "connection",
        action: "ping",
        data: {},
      });
    },
    onEvent() {
      const events = ["onmessage", "onclose", "onerror", "onopen"];
      events.forEach((eventType) => {
        webSocket[eventType] = (event) => {
          clearInterval(reconnectInterval);
          clearTimeout(pingTimeout);
          clearTimeout(checkPongTimeout);
          // console.log('Event: ', event, eventType)
          pingTimeout = setTimeout(actions.ping, config.pingInterval);
          checkPongTimeout = setTimeout(
            actions.checkPong,
            config.pingInterval + config.pongThreshold
          );

          if (store) {
            actions.passToStore("SOCKET_" + eventType, event);
          }

          if (eventType === "onerror" || eventType === "onclose") {
            reconnectInterval = setInterval(
              actions.connect,
              config.reconnectInterval
            );
            clearTimeout(pingTimeout);
            clearTimeout(checkPongTimeout);
          }
        };
      });
    },

    passToStore(eventName, event) {
      if (!eventName.startsWith("SOCKET_")) {
        return;
      }

      let method = "commit";
      let target = eventName.toUpperCase();
      let msg = event;

      if (event.data) {
        msg = JSON.parse(event.data);
        // console.log('Msg recieved: ', msg)

        if (msg.type === "ack") {
          delete pendingRequests[msg.transaction_id];

          return;
        }

        if (msg.mutation) {
          target = [msg.namespace || "", msg.mutation]
            .filter((e) => !!e)
            .join("/");
        } else if (msg.action) {
          method = "dispatch";
          target = [msg.namespace || "", msg.action]
            .filter((e) => !!e)
            .join("/");
        }
      }
      store[method](target, msg);
    },
  };

  return {
    init: actions.init,
    connect: actions.connect,
    sendObj: actions.sendObj,
    close: actions.close,
  };
}

const socket = makeSocket();
window.socket = socket;

export default socket;
