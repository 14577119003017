#<template>
  <v-dialog v-model="showDialog"
            persistent
            width="290px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Entsperren</span>
      </v-card-title>
      <v-card-text>
        <label>Geben Sie ihr Passwort ein um die App zu entsperren.</label>
        <v-autocomplete
          v-model="username"
          :items="getAvailableUsers"
          tabindex="1"
          clearable
          item-text="username"
          item-value="username"
        ></v-autocomplete>
        <v-text-field
          tabindex="2"
          autofocus
          type="password"
          label="Passwort"
          v-model="password"
          v-on:input="clearError"
          autocomplete="new-password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn tabindex="4" class="red--text darken-1"  @click.native="cancelDialog">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn tabindex="3" class="green--text darken-1"  @click.native="sendUnlimit">Entsperren</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Storage from '../../lib/storage'
export default {
  name: 'UnlimitDialog',
  data: () => {
    return {
      username: undefined,
      currentUser: undefined,
      password: ''
    }
  },
  beforeUpdate () {
    this.currentUser = this.$store.getters['auth/getCurrentUsername']
  },
  computed: {
    showDialog () {
      return this.$store.getters['viewState/getShowUnlimitDialog']
    },
    getAvailableUsers () {
      return this.$store.getters['auth/getAvailableUsers']
    },
    getPasswordCheckError () {
      return this.$store.getters['auth/getPasswordCheckError']
    }
  },
  methods: {
    cancelDialog () {
      this.password = ''
      this.$store.commit('viewState/setShowUnlimitDialog', false)
    },
    clearError () {
      this.$store.commit('auth/setPasswordCheckError', undefined)
    },
    sendUnlimit () {
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'login',
        data: {
          username: this.username,
          password: this.password,
          permanent: true
        }
      })
      this.password = undefined
      if (this.currentUser !== this.username) {
        Storage.remove('token')
      }
    }
  }
}
</script>
