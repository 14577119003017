<template>
  <v-flex xs12>
    <v-container fluid style="min-height: 0;" grid-list-lg>
      <v-layout v-if="!bucket" column>
        <bucket-selector v-model="bucket"/>
      </v-layout>
      <v-layout v-else column>
        <v-flex xs12 v-if="getFilteredServices.length === 0" headline>
          <h6 style="text-align: center">Keine Dienste gefunden</h6>
        </v-flex>
        <v-flex xs12 v-for="service in getFilteredServices" v-bind:key="service.offer_id">
          <service-item-view
            v-bind:service="service"
            v-on:showServiceTimeDialog="showServiceTimeDialog"
            v-on:timeSet="timeSet"
          ></service-item-view>
        </v-flex>
      </v-layout>
    </v-container>
    <service-time-dialog
      :time="time"
      :editMode="editMode"
      :service="service"
      v-on:timeSet="timeSet"
    ></service-time-dialog>
    <feedback-dialog
      :message="message"
    ></feedback-dialog>
  </v-flex>
</template>

<script>
import ServiceItemView from './ServiceItemView.vue'
import ServiceTimeDialog from './ServiceTimeDialog.vue'
import time from '../../lib/time'
import BucketSelector from '../main/BucketSelector.vue'
import FeedbackDialog from './FeedbackDialog.vue'

export default {
  name: 'ServiceListView',
  components: {
    ServiceTimeDialog,
    ServiceItemView,
    BucketSelector,
    FeedbackDialog
  },
  data: () => {
    return {
      time: undefined,
      editMode: undefined,
      service: undefined,
      message: undefined
    }
  },
  created () {
    if (!this.$store.getters['service/isInitialized']) {
      this.$socket.sendObj({
        namespace: 'service',
        action: 'get'
      })
      this.$socket.sendObj({
        namespace: 'auth',
        action: 'getAvailableUsers'
      })
    }
  },
  computed: {
    getFilteredServices () {
      return this.$store.getters['service/getSelectedBucket'](this.bucket)
    },
    bucket: {
      get () { return this.$store.getters['viewState/getBucket'] },
      set (value) { this.$store.commit('viewState/setBucket', value) }
    }
  },
  methods: {
    showServiceTimeDialog (options) {
      this.service = options.service
      this.editMode = options.editMode
      let minuteDivider = this.$store.getters['config/getMinuteDivider']
      this.time = time.getNextAllowedTime(minuteDivider)
      this.$store.commit('viewState/setShowServiceTimeDialog', true)
    },
    timeSet (data) {
      this.bucket = undefined
      this.message = data.msg
      this.$store.commit('viewState/setShowFeedbackDialog', true)
    }
  }
}
</script>
