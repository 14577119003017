<template>
  <v-dialog persistent v-model="show" lazy width="290px">
    <v-time-picker
      v-model="internalTime"
      scrollable
      format="24hr"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
    >
      <template>
        <v-card-actions>
          <v-btn flat @click.native="cancel">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="saveTime">Übernehmen</v-btn>
        </v-card-actions>
      </template>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import time from "../../lib/time";

export default {
  name: "ServiceTimeDialog",
  props: ["time", "service", "editMode"],

  data: () => {
    return {
      internalTime: undefined,
    };
  },

  watch: {
    time(newTime) {
      this.internalTime = newTime;
    },
  },

  computed: {
    show: {
      get: function() {
        return this.$store.getters["viewState/getShowServiceTimeDialog"];
      },
      set: function(value) {
        this.$store.commit("viewState/setShowServiceTimeDialog", value);
      },
    },
  },

  methods: {
    /**
     * Gets a single integer to test if it's an allowed hour
     * @param  int      hour
     * @return boolean  allowed
     */
    allowedHours(hour) {
      const currentTimeObject = time.string2Object(this.internalTime);

      switch (this.editMode) {
        case "end":
          return this.allowedEndHour(hour, currentTimeObject);

        case "start":
          return this.allowedStartHour(hour, currentTimeObject);
      }

      return true;
    },

    /**
     * Checks if the given minute is applicable for selection
     * @param  int minute
     * @return bool
     */
    allowedMinutes(minute) {
      const currentTimeObject = time.string2Object(this.internalTime);

      switch (this.editMode) {
        case "end":
          return this.allowedEndMinute(minute, currentTimeObject);

        case "start":
          return this.allowedStartMinute(minute, currentTimeObject);
      }

      return true;
    },

    /**
     * Check if the given hour is an applicable start hour (atm)
     * @param  int hour
     * @return bool
     */
    allowedStartHour(hour) {
      const minuteDivider = this.$store.getters["config/getMinuteDivider"];
      const allowedRange = this.$store.getters["config/getAllowedRange"];

      return time.isAllowedHour(hour, minuteDivider, allowedRange);
    },

    /**
     * Checks if the given minutes is an applicable start minute
     * @param  int minute
     * @return bool
     */
    allowedStartMinute(minute, currentTimeObject) {
      const minuteDivider = this.$store.getters["config/getMinuteDivider"];
      const allowedRange = this.$store.getters["config/getAllowedRange"];

      return time.isAllowedMinute(
        currentTimeObject.hour,
        minute,
        minuteDivider,
        allowedRange
      );
    },

    buildMomentFromServiceMoment(serviceMoment, hour, minute) {
      // Build TestMoment
      let testMoment = serviceMoment.clone();

      // with current test hour and minutes
      testMoment.hour(hour);
      testMoment.minute(minute);

      // if testMoment is before StartMoment, we encountered a day barrier
      if (testMoment.isBefore(serviceMoment)) {
        testMoment.add(1, "d");
      }

      return testMoment;
    },

    /**
     * Check if the given hour is an applicable start hour (atm)
     * @param  int hour
     * @return bool
     */
    allowedEndHour(hour, currentTimeObject) {
      const minuteDivider = this.$store.getters["config/getMinuteDivider"];
      const serviceStart = this.service.booked_start;
      const maxEnd = time.getNextAllowedTime(minuteDivider);

      const serviceStartMoment = time.toMoment(serviceStart);

      // Build TestMoment
      let testMoment = this.buildMomentFromServiceMoment(
        serviceStartMoment,
        hour,
        currentTimeObject.minute
      );

      // Build EndMoment
      const maxEndObject = time.string2Object(maxEnd);
      let maxEndMoment = this.buildMomentFromServiceMoment(
        serviceStartMoment,
        maxEndObject.hour,
        maxEndObject.minute
      );

      return (
        testMoment.isBetween(serviceStartMoment, maxEndMoment, null, "[]") ||
        serviceStartMoment.hour() === hour ||
        testMoment.hour() === maxEndMoment.hour()
      );
    },

    /**
     * Checks if the given minutes is an applicable start minute
     * @param  int minute
     * @return bool
     */
    allowedEndMinute(minute, currentTimeObject) {
      const minuteDivider = this.$store.getters["config/getMinuteDivider"];
      const serviceStart = this.service.booked_start;
      const maxEnd = time.getNextAllowedTime(minuteDivider);

      const serviceStartMoment = time.toMoment(serviceStart);

      // Build TestMoment
      let testMoment = this.buildMomentFromServiceMoment(
        serviceStartMoment,
        currentTimeObject.hour,
        minute
      );

      // Build EndMoment
      const maxEndObject = time.string2Object(maxEnd);
      let maxEndMoment = this.buildMomentFromServiceMoment(
        serviceStartMoment,
        maxEndObject.hour,
        maxEndObject.minute
      );

      return (
        testMoment.isBetween(serviceStartMoment, maxEndMoment, null, "[]") &&
        minute % 15 === 0
      );
    },

    cancel() {
      this.$store.commit("viewState/setShowServiceTimeDialog", false);
    },

    saveTime() {
      this.$store.commit("viewState/setShowServiceTimeDialog", false);
      let preproposal = this.$store.getters[
        "preproposal/getProposalForOfferId"
      ](this.service.offer_id);

      switch (this.editMode) {
        case "start":
          this.$socket.sendObj({
            namespace: "preproposal",
            action: "shift_start",
            data: {
              offer_id: this.service.offer_id,
              entered: time.getTimestringFromSimpleTime(
                this.internalTime,
                this.service.booked_start
              ),
              rereferenced_preproposal_id: preproposal ? preproposal.id : null,
            },
          });
          this.$emit("timeSet", {
            msg: "Ihre Schicht hat begonnen",
          });

          break;

        case "end":
          this.$socket.sendObj({
            namespace: "preproposal",
            action: "shift_end_by_clock",
            data: {
              offer_id: this.service.offer_id,
              entered: this.internalTime,
              rereferenced_preproposal_id: preproposal ? preproposal.id : null,
            },
          });
          this.$emit("timeSet", {
            msg: "Ihre Schicht ist beendet. Schönen Feierabend.",
          });

          break;
        default:
          console.warn("Editmode value not valid: ", this.$data.editMode);
      }
    },
  },
};
</script>
