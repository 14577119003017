<template>
  <div class="bucketItem" v-on:click="setBucket">
    <v-progress-circular v-if="selected" indeterminate></v-progress-circular>
    <label v-else>{{bucket}}</label>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'BucketItem',
  props: ['bucket'],
  data () {
    return {
      selected: false
    }
  },
  computed: {
    selectedBucket: {
      get () { return this.$store.getters['viewState/getBucket'] },
      set (value) { this.$store.commit('viewState/setBucket', value) }
    }
  },
  methods: {
    setBucket () {
      this.selected = true
      setTimeout(() => {
        Vue
          .nextTick()
          .then(() => {
            this.selectedBucket = this.bucket
            this.selected = false
          })
      }, 50)
    }
  }
}
</script>

<style lang="scss">
  .bucketItem {
    height: 100px;
    text-align: center;
    font-size: 30px;
    display: block;
    padding: 30px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

    &.selected {
      background-color: grey;
      color: white;
    }
    span {
      background-color: red;
      width: 100px;
      height: 20px;
      display: inline-block;
    }
  }
</style>
