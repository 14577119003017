export default {
  connection: process.env.NODE_ENV === 'development'
    ? 'ws://192.168.178.30:5000/ws'
    : process.env.VUE_APP_INSITU_BASE_URL,
  devtools: process.env.NODE_ENV === 'development',
  pingInterval: process.env.NODE_ENV === 'development'
    ? 60000
    : 5000,
  pongThreshold: process.env.NODE_ENV === 'development'
    ? 30000
    : 3000,
  reconnectInterval: 10000
}
