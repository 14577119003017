import Vue from 'vue'
import Vuex from 'vuex'

import auth from './authStore'
import service from './serviceStore'
import socket from './socketStore'
import preproposal from './preproposalStore'
import config from './configStore'
import viewState from './viewStateStore'
import connection from './connectionStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    service,
    socket,
    preproposal,
    config,
    viewState,
    connection
  }
})

function onlineAgain () {
  console.log('Online Again... Lets reconnect')
  store.commit('online')
}

function offlineNow () {
  console.log('Offline now...')
  store.commit('online')
}

/* function updateConnectionStatus () {
  store.commit('online')
} */

function onResize () {
  store.commit('viewState/setScreenSize', window.innerWidth)
}

function handleVisibilityChange () {
  // reload Site if socket is brocken
  if (!document.hidden && store.$socket.readyState > 1) {
    location.reload()
  }
}

window.addEventListener('online', onlineAgain)
window.addEventListener('offline', offlineNow)
window.addEventListener('resize', onResize)

document.addEventListener('visibilitychange', handleVisibilityChange, false)

window.document.store = store
export default store
